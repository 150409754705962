import React from 'react';
import './button.scss';

const Button = ({children}) => (
    <button className='button'>
        {children}
    </button>
);

export default Button;
