import React, { useState } from "react";
import { BiChevronDown} from "react-icons/bi"
import "./accordion.scss"
import { Title } from '../../components/Text/Title/Title';
import SubTitle from "../../components/Text/SubTitle/SubTitle";

const Accordion = (props) => {
    const [active, setActive] = useState(false);

    return (
        <div className={`accordion ${active ? 'active' : ''}`}>
            <div className="accordion__title" onClick={() => setActive(!active)}>
                <Title>{props.btn}</Title>
                <div className="accordion__icon">
                    <BiChevronDown className='bx bxs-chevron-down'></BiChevronDown>
                </div>
            </div>
            <div className="accordion__content">
                <SubTitle>{props.text}</SubTitle>
            </div>
        </div>
    )
}

export default Accordion