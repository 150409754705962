// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/success.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".promo {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n  padding: 32px 16px;\n}\n@media (min-width: 768px) {\n  .promo {\n    padding: 64px 16px;\n  }\n}\n@media (min-width: 1240px) {\n  .promo {\n    padding: 80px 16px;\n  }\n}\n.promo__text {\n  max-width: 650px;\n  color: white;\n  font-size: 18px;\n  font-weight: 700;\n  line-height: 24px;\n  letter-spacing: 0.64px;\n  text-align: center;\n  margin-bottom: 32px;\n}\n@media (min-width: 1024px) {\n  .promo__text {\n    font-size: 30px;\n    line-height: 42px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/PageOne/Promo/promo.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yDAAA;EACA,sBAAA;EACA,2BAAA;EACA,kBAAA;AACJ;AACI;EATJ;IAUQ,kBAAA;EAEN;AACF;AAAI;EAbJ;IAcQ,kBAAA;EAGN;AACF;AADI;EACI,gBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;AAGR;AADQ;EAVJ;IAWQ,eAAA;IACA,iBAAA;EAIV;AACF","sourcesContent":[".promo {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-image: url(../../img/success.png);\n    background-size: cover;\n    background-position: center;\n    padding: 32px 16px;\n\n    @media (min-width: 768px) {\n        padding: 64px 16px;\n    }\n\n    @media (min-width: 1240px) {\n        padding: 80px 16px;\n    }\n\n    &__text {\n        max-width: 650px;\n        color: white;\n        font-size: 18px;\n        font-weight: 700;\n        line-height: 24px;\n        letter-spacing: 0.64px;\n        text-align: center;\n        margin-bottom: 32px;\n\n        @media (min-width: 1024px) {\n            font-size: 30px;\n            line-height: 42px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
