import React from 'react';
import './firstVisit.scss';
import {motion} from 'framer-motion';
import StepOne from '../../img/stepOne.png';
import StepTwo from '../../img/stepTwo.png';
import StepThree from '../../img/stepThree.png';
import StepFour from '../../img/stepFour.png';

const firstVisitArray = [
    {
        image: StepOne,
        name: 'Опитування',
        description: 'Виявлення скарг та історії хвороби (анамнез), вивчення досліджень (МРТ, КТ, рентген, аналізи, тощо).'
    },
    {
        image: StepTwo,
        name: 'Загальний огляд',
        description: `Оцінка стану опорно-рухового апарату, постави, хребта, суглобів та огляд місця
        болю/скарг, тестування,  виявлення причини болю.`
    },
    {
        image: StepThree,
        name: 'Лікування',
        description: 'Підбирається індивідуально на основі діагностики'
    },
    {
        image: StepFour,
        name: 'Рекомендації',
        description: `Обговорюємо подальшу тактику лікування. Створюю,
        при потребі, програму реабілітації в спеціальному застосунку, даю рекомендації щодо
        запобігання повторних загострень та підтримки отриманого результату.`
    },
];

const animate = {
    hidden: {
        scale: 0.5,
        opacity: 0,
        y: 10,
        transition: {
            type: 'scale',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const FirstVisit = () => (
    <div className='firstVisit'>
        <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="firstVisit__title">Як проходить перша зустріч</motion.div>
        {firstVisitArray.map(item => (
            <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="firstVisit__row">
                <img className="firstVisit__image" src={item.image}/>
                <div className="firstVisit__info">
                    <span className="firstVisit__name">{item.name}</span>
                    <span className="firstVisit__description">{item.description}</span>
                </div>
            </motion.div>
        ))}
    </div>
);

export default FirstVisit;