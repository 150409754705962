import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import './promo.scss';
import {motion} from 'framer-motion';

const animate = {
    hidden: {
        scale: 0.5,
        opacity: 0,
        y: 10,
        transition: {
            type: 'scale',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const Promo = () => (
    <div className="promo">
        <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="promo__text">Відновлення вашого здоров'я - це наш спільний успіх.</motion.div>
        <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}} >
            <Link to='/contacts'><Button>Записатись</Button></Link>
        </motion.div>
    </div>
);

export default Promo;