import React from 'react';
import './services.scss';
import { motion } from "framer-motion";

const navVariants1 = {
    hidden: {
        scale: 0.5,
        opacity: 0,
        y: 10,
        transition: {
            type: 'scale',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const navVariants2 = {
    hidden: {
        scroll: 0,
        opacity: 0,
        y: 50,
        transition: {
            type: 'scroll',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scroll: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const navVariants3 = {
    hidden: {
        scroll: 0,
        opacity: 0,
        y: 50,
        transition: {
            type: 'scroll',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scroll: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const Services = () => (
    <div className='services'>
        <motion.div variants={navVariants1} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="services__title">Займаюся лікуванням</motion.div>
        <div className='services__info'>
            <motion.div variants={navVariants2} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className='services__children'>
                <div className='services__childrenTitle'>Діти</div>
                <div className='services__childrenList'>
                    <ul>
                        <li>дисплазія</li>
                        <li>кривошия</li>
                        <li>затримка фізичного розвитку</li>
                        <li>гіпертонус</li>
                        <li>сколіоз</li>
                        <li>вальгус стоп</li>
                    </ul>
                </div>
            </motion.div>
            <motion.div variants={navVariants3} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className='services__adult'>
                <div className='services__adultTitle'>Дорослі</div>
                <div className='services__adultList'>
                    <ul>
                        <li>грижі, протрузії</li>
                        <li>біль в мʼязах</li>
                        <li>біль в спині</li>
                        <li>біль в кінцівках</li>
                        <li>спортивні травми</li>
                        <li>переломи</li>
                        <li>відновлення після операції</li>
                    </ul>
                </div>
            </motion.div>
        </div>
    </div>
);

export default Services;