import React from 'react';
import './whyMe.scss';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
import Button from '../../components/Button/Button';
import Individual from '../../img/individual.png';
import Experience from '../../img/experience.png';
import Complex from '../../img/complex.png';
import Result from '../../img/result.png';

const whyMeArray = [
    {
        image: Individual,
        name: 'Індивідуальний підхід',
        descriprion: `Кожен пацієнт - це унікальна історія, і я завжди працюю
        з кожним індивідуально, розробляючи програму лікування, яка відповідає вашим потребам.`
    },
    {
        image: Experience,
        name: 'Досвід і професіоналізм',
        descriprion: `Маю багаторічний досвід у сфері реабілітації та стежу за
        останніми досягненнями в галузі. Ви можете бути впевнені в якісному медичному обслуговуванні.`
    },
    {
        image: Complex,
        name: 'Комплексний піхід',
        descriprion: `Моя методика лікування поєднує фізичні вправи, масаж,
        та інші методи з метою максимального відновлення вашого здоров'я та функціональності.`
    },
    {
        image: Result,
        name: 'Результати',
        descriprion: `Мої пацієнти досягають видимих та стійких результатів у
        відновленні рухливості та якості життя. Ваше здоров'я - це моя головна мета.`
    },
]

const animate = {
    hidden: {
        scale: 0.5,
        opacity: 0,
        y: 10,
        transition: {
            type: 'scale',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const WhyMe = () => (
    <div className='whyMe'>
        <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="whyMe__title">Чому вам потрібно саме до мене</motion.div>
        <div className="whyMe__reasons">
            {whyMeArray.map(item => (
                <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="whyMe__item">
                    <img className="whyMe__image" src={item.image} alt={item.name} />
                    <div className="whyMe__name">{item.name}</div>
                    <div className="whyMe__description">{item.descriprion}</div>
                </motion.div>
            ))}
        </div>
        <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="whyMe__promo">
            Якщо ти шукаєш <span>кваліфікованого реабілітолога</span>, тоді вже зараз записуйся на <span>консультацію</span>.
        </motion.div>
        <motion.div variants={animate} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className="whyMe__button">
            <Link to='/contacts'><Button>Записатись</Button></Link>
        </motion.div>
    </div>
);

export default WhyMe;