// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/logo-favicon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading {\n  position: absolute;\n  background-color: white;\n  width: 100vw;\n  height: 100vh;\n  z-index: 10000;\n  overflow: hidden;\n}\n\n.loading__image {\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: auto;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 20%;\n  background-position: 50% 50%;\n  z-index: 100000;\n}\n@keyframes myAnim {\n  0% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(1.1);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n@media (min-width: 1024px) {\n  .loading__image {\n    background-size: 10%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/preloader/loader.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,MAAA;EACA,UAAA;EACA,yDAAA;EACA,4BAAA;EACA,oBAAA;EACA,4BAAA;EACA,eAAA;AACJ;AACI;EACI;IACI,mBAAA;EACV;EAEM;IACI,qBAAA;EAAV;EAGM;IACI,mBAAA;EADV;AACF;AAII;EAzBJ;IA0BQ,oBAAA;EADN;AACF","sourcesContent":[".loading {\n    position: absolute;\n    background-color: white;\n    width: 100vw;\n    height: 100vh;\n    z-index: 10000;\n    overflow: hidden;\n}\n\n.loading__image {\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: auto;\n    background-image: url(../img/logo-favicon.png);\n    background-repeat: no-repeat;\n    background-size: 20%;\n    background-position: 50% 50%;\n    z-index: 100000;\n\n    @keyframes myAnim {\n        0% {\n            transform: scale(1);\n        }\n\n        50% {\n            transform: scale(1.1);\n        }\n\n        100% {\n            transform: scale(1);\n        }\n    }\n\n    @media (min-width: 1024px) {\n        background-size: 10%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
