import React from 'react';
import { Link } from "react-router-dom";
import Button from '../../components/Button/Button';
import './header.scss';
import {motion} from 'framer-motion'

const animate = {
    hidden:{
        x: -100,
        opacity: 0,
    },
    visible: custom => ({
        x: 0,
        opacity: 1,
        transition: {delay: custom * 0.5 }
    }),
};

const Header = () => (
    <motion.div
    initial='hidden'
    whileInView='visible'
    viewport={{amout: 0.1, once: true,}}
    className='header'>
        <motion.div className="header__info">
            <motion.div custom={1} variants={animate} className='header__text'>Ваша дорога до здорового та активного життя починається тут</motion.div>
            <motion.div custom={1} variants={animate}>
                <Link to='/contacts'><Button>Записатись</Button></Link>
            </motion.div>
        </motion.div>
    </motion.div>
);

export default Header;
