import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slider.scss';

// images
import Feedback1 from '../../img/feedback1.png';
import Feedback2 from '../../img/feedback2.png';
import Feedback3 from '../../img/feedback3.png';
import Feedback4 from '../../img/feedback4.png';
import Feedback5 from '../../img/feedback5.png';
import Feedback6 from '../../img/feedback6.png';
import Feedback7 from '../../img/feedback7.png';
import Feedback8 from '../../img/feedback8.png';
import Feedback9 from '../../img/feedback9.png';
import Feedback10 from '../../img/feedback10.png';
import Feedback11 from '../../img/feedback11.png';
import Feedback12 from '../../img/feedback12.png';
import Feedback13 from '../../img/feedback13.png';
import Feedback14 from '../../img/feedback14.png';

const feedbackArray = [
    Feedback1, Feedback2, Feedback3, Feedback4, Feedback5, Feedback6, Feedback7,
    Feedback8, Feedback9, Feedback10, Feedback11, Feedback12, Feedback13, Feedback14
]

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            }
          },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 2,
          }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
      ]
};

const SliderFeedback = () => (
    <div className="feedback">
        <div className="feedback__title">Відгуки</div>
        <Slider {...settings}>
            {feedbackArray.map(item => (
                <div className="feedback__item">
                    <img src={item} alt="feedback image" />
                </div>
            ))}
        </Slider>
    </div>
);

export default SliderFeedback;