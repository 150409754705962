import React from 'react';
import HeaderExer from './Header/HeaderExer';;
import PlayList from './VideoSlider/PlayList';



const PageVideoCouse = () => (
    <>
        <HeaderExer />
        <PlayList />
    </>
);

export default PageVideoCouse;
