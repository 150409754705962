import React,{forwardRef} from 'react'
import './title.scss'
import {motion} from 'framer-motion'

export const Title = forwardRef (({children}, ref,) => (
    <div ref={ref} className='title'>
        {children}
    </div>
));

export default Title
export const MTitle = motion (Title);
