import React from 'react'
import HeaderPrice from './Header/HeaderPrice'
import Price from './Price/Price'

function PagePrice() {
    return (
    <div>
        <HeaderPrice/>
        <Price/>
    </div>
    )
}

export default PagePrice
