import React from 'react';
import './subtitle.scss';

const SubTitle = ({children}) => (
    <div className='subtitle'>
        {children}
    </div>
)

export default SubTitle;
