import React from 'react';
import Title from '../../components/Text/Title/Title';
import './header-exer.scss';

const HeaderExer = () => (
    <div className='header-exer'>
        <img className='header-exer__img'/>
        <h1 className='header-exer__text'>
            <Title>Реабілітація вдома</Title>
        </h1>
    </div>
);

export default HeaderExer;
