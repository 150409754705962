import React from 'react';
import './methodItem.scss';

const MethodItem = ({icon, name}) => (
    <div className='methodItem'>
        <div className='methodItem__icon'>
            <img src={icon} alt={name} />
        </div>
        <div className='methodItem__text'>{name}</div>
    </div>
);

export default MethodItem;