import React from 'react';
import MethodItem from '../../components/MethodItem/MethodItem';
import './methods.scss';
import { motion } from "framer-motion";
import Massage from '../../img/massage.png';
import Rehab from '../../img/rehab.png';
import Manual from '../../img/manual.png';
import Kinezio from '../../img/kinezio.png';
import Pirm from '../../img/pirm.png';
import Osteo from '../../img/osteo.png';
import PNF from '../../img/pnf.png';
import Malligan from '../../img/malligan.png';
import Kineziotape from '../../img/kineziotape.png';
import Yumeiho from '../../img/yumeiho.png';

const methods = [
    {
        name: 'Масаж',
        icon: Massage,
    },
    {
        name: 'Реабілітація',
        icon: Rehab,
    },
    {
        name: 'Мануальна терапія',
        icon: Manual,
    },
    {
        name: 'Прикладна кінезіологія',
        icon: Kinezio,
    },
    {
        name: 'ПІРМ (постізометрична релаксація мʼязів)',
        icon: Pirm,
    },
    {
        name: 'Елементи остеопатії',
        icon: Osteo,
    },
    {
        name: 'PNF (пропріоцептивна нейромʼязова фасілітація)',
        icon: PNF,
    },
    {
        name: 'Концепція Малліган',
        icon: Malligan,
    },
    {
        name: 'Кінезіотейпування',
        icon: Kineziotape,
    },
    {
        name: 'Юмейхо терапія',
        icon: Yumeiho,
    },
];

const navVariants1 = {
    hidden: {
        scale: 0.5,
        opacity: 0,
        y: 10,
        transition: {
            type: 'scale',
            stiffness: 300,
            damping: 140,
        },
    },
    show: {
        scale: 1,
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    },
};

const animate = {
    hidden:{
        y: 100,
        opacity: 0,
    },
    visible: custom => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.1 }
    }),
};

const Methods = () => (
    <div className='methods'>
        <motion.div variants={navVariants1} initial="hidden" whileInView="show" viewport={{amout: 0.1, once: true,}}  className='methods__title'>Методи, які використовую в роботі</motion.div>
        <div className='methods__list'>
            {methods.map(item =>
                <motion.div variants={animate} initial='hidden' whileInView='visible' viewport={{amout: 0.1, once: true,}} >
                    <MethodItem name={item.name} icon={item.icon} />
                </motion.div>
            )}
        </div>
    </div>
);

export default Methods;