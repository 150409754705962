import React from 'react'
import Contacts from './Contacts/Contacts'

const PageContacts = () => {

    return (
    <div >
        <Contacts/>
    </div>
    )
}

export default PageContacts
